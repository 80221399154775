.auth {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	.title-icon.green {
		color: green;
	}

	.link {
		text-decoration: none;
		color: steelblue;
		font-size: 0.8rem;
		font-weight: bold;
	}

	.error {
		color: red;
		font-weight: bold;
		text-align: center;
	}
}
