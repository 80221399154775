#contact {
	min-height: calc(100vh / 3);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 20px 0;
	text-align: center;
	// border: 1px ridge crimson;

	h2 {
		flex: 1;
	}

	p {
		margin: 10px 0;
	}

	.contact-container {
		flex: 11;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;

		.left,
		.right {
			flex: 1;
		}

		.left {
			display: none;
		}

		.right {
			display: flex;
			justify-content: center;

			.MuiCard-root {
				max-width: 80%;
				background-color: #16161a;
				border-radius: 20px;
				padding: 30px;
				color: whitesmoke;
				-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
				box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

				.divider {
					border-bottom: 2px ridge silver;
					margin: 10px 0;
				}

				input,
				textarea,
				button {
					color: whitesmoke;
				}

				.content {
					// border: 1px ridge yellow;
					padding: 0;
				}

				.actions {
					// border: 1px ridge dodgerblue;
					display: flex;
					justify-content: center;
					padding: 0;

					button {
						width: 150px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 700px) {
	#contact {
		.contact-container {
			.left {
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-width: 80%;
					background-color: steelblue;
					padding: 20px;
					border-radius: 25%;
				}
			}
		}
	}
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
