// #testimonials {
// 	background: #16161a;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	h2 {
// 		font-size: 50px;
// 	}

// 	.testimonial-container {
// 		width: 100%;
// 		height: 80%;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;

// 		.card {
// 			background: #242629;
// 			width: 250px;
// 			height: 70%;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-around;
// 			padding: 20px;
// 			border-radius: 10px;
// 			transition: all 0.5s ease;
// 			-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
// 			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

// 			&.featured {
// 				width: 300px;
// 				height: 75%;
// 				margin: 0 40px;
// 			}

// 			&:hover {
// 				transform: scale(1.1);
// 			}

// 			.top {
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;

// 				.icon {
// 					font-size: 40px;
// 				}

// 				img {
// 					height: 60px;
// 					width: 60px;
// 					border-radius: 100%;
// 					object-fit: cover;
// 					margin: 0 30px;
// 				}
// 			}

// 			.center {
// 				padding: 10px;
// 				background: #2cb67d;
// 				border-radius: 10px;
// 			}

// 			.bottom {
// 				display: flex;
// 				flex-direction: column;
// 				justify-content: center;
// 				align-items: center;

// 				h4 {
// 					margin-bottom: 5px;
// 				}

// 				h5 {
// 					color: #94a1b2;
// 				}
// 			}
// 		}
// 	}
// }

#testimonials {
	height: calc(100vh / 3);
	background: #16161a;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 20px 0;
	text-align: center;
	// border: 1px ridge white;

	.testimonial-container-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.testimonial-container-standard {
		display: none;
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 700px) {
	#testimonials {
		.testimonial-container-mobile {
			display: none;
		}

		h2 {
			flex: 1;
			margin: 10px 0;
		}

		.testimonial-container-standard {
			flex: 10;
			display: flex;
			justify-content: center;
			align-items: center;
			// border: 1px ridge salmon;

			.card {
				background-color: #242629;
				width: 33%;
				height: 80%;
				margin: 0 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				padding: 0 15px;
				border-radius: 10px;
				transition: all 0.5s ease;
				-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
				box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

				&.featured {
					width: 35%;
					height: 90%;
					padding: 0 25px;
				}

				&:hover {
					transform: scale(1.1);
				}

				.top {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: -15px;

					.icon {
						font-size: 25px;
					}

					img {
						width: 40px;
						height: 40px;
						border-radius: 100%;
						object-fit: cover;
						margin: 0 5px;
					}
				}

				.center {
					padding: 15px;
					background-color: #2cb67d;
					border-radius: 10px;
					font-size: 12px;
				}

				.bottom {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					h5 {
						margin-bottom: 5px;
					}

					h6 {
						color: #94a1b2;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
