.summary {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	position: relative;

	.back {
		position: absolute;
		top: 20px;
		left: 30px;
		color: dodgerblue !important;
	}

	.select {
		background-color: #16161a;
		border-radius: 20px;
		color: whitesmoke;
		font-size: 15px;
		margin: 15px 0;
		padding: 5px;
		text-align: center;
	}
}
