#portfolio {
	height: calc(100vh / 3);
	background: #16161a;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 20px 0;
	text-align: center;

	h2 {
		margin-top: 5px;
		margin-bottom: 10px;
	}

	.portfolio-container {
		display: flex;
		justify-content: center;
		overflow-y: scroll;

		.mobile {
			width: 100vw;
			display: flex;
			flex-direction: column;
			overflow-y: scroll;

			.mobile-header {
				background: #6d41a1;
				width: 80%;
				padding: 7px;
				border-radius: 10px;
				margin: 15px 0;
				text-align: center;
				align-self: center;

				h5 {
					font-weight: 400;
				}
			}

			.slide-container {
				display: flex;
				justify-content: center;
			}
		}
	}

	ul {
		display: none;
	}

	.portfolio-item-container {
		display: none;
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
	#portfolio {
		height: unset;
		max-height: calc(100vh / 2);
		justify-content: center;
		align-items: center;

		.portfolio-container {
			display: none;
		}

		ul {
			margin: 10px;
			padding: 0;
			list-style: none;
			display: flex;

			li {
				font-size: 14px;
				margin-right: 50px;
				padding: 7px;
				border-radius: 10px;
				cursor: pointer;

				&.active {
					background: #6d41a1;
				}
			}
		}

		.portfolio-item-container {
			width: 90%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			overflow-y: auto;

			.item {
				width: 320px;
				height: 260px;
				border-radius: 20px;
				border: 2px solid #6d41a1;
				margin: 10px 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				transition: all 1s ease;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
					border-radius: 20px;
					object-fit: cover;
					z-index: 1;
				}

				h4 {
					position: absolute;
					font-size: 20px;
				}

				&:hover {
					background: #6d41a1;

					img {
						opacity: 0.2;
						z-index: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
