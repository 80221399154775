#home {
	height: calc(100vh - 56px);
	padding-top: 56px;
}

@media only screen and (min-width: 600px) {
	#home {
		height: calc(100vh - 64px);
		padding-top: 64px;
	}
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
