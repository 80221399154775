#works {
	height: calc(100vh / 3);
	background-color: #242629;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 20px 0;
	text-align: center;
	// border: 1px ridge crimson;

	.slide-container {
		display: flex;
		justify-content: center;
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
