#intro {
	// border: 1px ridge red;
	height: calc(100vh / 3);
	background: #242629;
	display: flex;

	.left,
	.right {
		flex: 0.5;
		overflow: hidden;
	}

	.left {
		// border: 1px ridge dodgerblue;
		.img-container {
			height: 100%;
			margin-top: 25px;
			background: #6d41a1;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			overflow: hidden;

			img {
				height: 90%;
				// margin-left: -15px;
			}
		}
	}

	.right {
		// border: 1px ridge seagreen;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		padding: 10px;

		.wrapper {
			text-align: center;
			// border: 1px ridge yellow;

			h1 {
				font-size: 18px;
				margin: 2px 0;
			}

			h2 {
				font-size: 16px;
				margin: 2px 0;

				span {
					font-size: inherit;
					color: red;
				}
			}
		}
	}

	a {
		position: absolute;
		bottom: 10px;
		left: 42.5%;

		.icon {
			font-size: 40px;
			color: whitesmoke;
			animation: arrowBlink 2s infinite;
		}

		@keyframes arrowBlink {
			100% {
				opacity: 0;
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	#intro {
		.right {
			.wrapper {
				h1 {
					font-size: 32px;
				}

				h2 {
					font-size: 24px;
				}

				h3 {
					font-size: 18px;
				}
			}
		}
	}
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
	#intro {
		height: calc(100vh / 2);

		.left {
			.img-container {
				width: 600px;
				height: 600px;
				float: right;
				position: unset;

				img {
					height: 85%;
				}
			}
		}

		.right {
			.wrapper {
				h1 {
					font-size: 50px;
				}

				h2 {
					font-size: 35px;
				}

				h3 {
					font-size: 30px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
	#intro {
		.left {
			.img-container {
				margin-top: 50px;
				width: 900px;
				height: 900px;

				img {
					height: 80%;
				}
			}
		}

		.right {
			position: relative;

			.wrapper {
				width: 100%;
				height: 100%;
				padding-left: 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: unset;

				h1 {
					font-size: 60px;
					margin: 10px 0;
				}

				h2 {
					font-size: 35px;
				}
			}

			a {
				.icon {
					font-size: 100px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1536px) {
}
