.works-slide {
	width: 85%;
	height: inherit;
	align-self: center;
	// border: 1px ridge crimson;

	.slick-arrow::before {
		color: whitesmoke !important;
	}

	.item {
		height: 225px;
		overflow: hidden;
		// border: 1px ridge yellow;

		background-color: #16161a;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		.left {
			flex: 4;
			height: inherit;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			padding: 5px;
			// border: 1px ridge dodgerblue;

			.icon-container {
				width: 30px;
				height: 30px;
				border-radius: 100%;
				background-color: coral;
				display: flex;
				justify-content: center;
				align-items: center;

				.icon {
					font-size: 20px;
				}
			}

			h2 {
				font-size: 15px;
			}

			p {
				font-size: 8px;
			}

			span {
				font-size: 12px;
				font-weight: 600;
				text-decoration: underline;
			}
		}

		.right {
			flex: 8;
			height: inherit;
			overflow: hidden;
			// border: 1px ridge rebeccapurple;

			img {
				height: 100%;
				transform: rotate(-10deg);
			}
		}
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
	.works-slide {
		.item {
			max-width: 500px;
			// height: 300px;

			.left {
				.icon-container {
					width: 35px;
					height: 35px;

					.icon {
						font-size: 25px;
					}
				}

				h2 {
					font-size: 20px;
				}

				p {
					font-size: 10px;
				}
			}
		}
	}
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
