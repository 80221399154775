.portfolio-slide {
	width: 85%;
	align-self: center;
	// border: 1px ridge crimson;

	.slick-arrow::before {
		color: whitesmoke !important;
	}

	.item {
		max-width: 175px;
		height: 100px;
		border-radius: 20px;
		border: 2px ridge #16161a;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: all 1s ease;
		overflow: hidden;
		cursor: pointer;
		// border: 1px ridge yellow;

		img {
			width: auto;
			height: 100%;
			object-fit: fill;
			z-index: 1;
		}

		h5 {
			display: none;
		}

		&:hover {
			background-color: #6d41a1;

			img {
				opacity: 0.2;
				z-index: 0;
			}

			h5 {
				display: block;
				font-size: 15px;
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}
