.navbar {
	width: 100vw;
	height: 56px;
	background: black;
	color: #6d41a1;
	position: fixed;
	top: 0;
	z-index: 3;
	transition: all 1s ease;

	.wrapper {
		box-sizing: border-box;
		height: inherit;
		padding: 10px 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left,
		.right {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.left {
			h4 {
				font-weight: 500;
			}

			a {
				display: flex;
				align-items: center;
				text-decoration: none;

				.logo {
					width: 80%;
				}
			}

			.item-container {
				display: flex;
				align-items: center;
				gap: 5px;

				.icon {
					font-size: 18px;
				}

				span {
					font-size: 14px;
					font-weight: 400;
				}

				&:last-child {
					margin-left: 20px;
				}
			}
		}

		.right {
			.hamburger {
				width: 32px;
				height: 24px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;

				span {
					width: 100%;
					height: 3px;
					background: #6d41a1;
					transform-origin: left;
					transition: all 1s ease;
				}
			}
		}
	}

	&.active {
		background: #6d41a1;
		color: whitesmoke;

		.hamburger {
			span {
				&:first-child {
					background: whitesmoke;
					transform: rotate(40deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:last-child {
					background: whitesmoke;
					transform: rotate(-40deg);
				}
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	.navbar {
		height: 64px;
	}
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
