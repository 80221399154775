.forgot {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	.card {
		max-width: 390px;

		.title-icon.blue {
			color: steelblue;
		}

		.txt {
			text-align: center;
		}
	}
}
