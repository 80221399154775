.testimonial-slide {
	width: 85%;
	align-self: center;
	// border: 1px ridge crimson;

	.slick-arrow::before {
		display: none;
	}

	.item {
		max-width: 250px;
		height: 220px;
		background-color: #242629;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 10px;
		margin-bottom: 10px;
		border-radius: 10px;
		-webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
		box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
		// border: 1px ridge yellow;

		.top {
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				font-size: 30px;
			}

			img {
				width: 50px;
				height: 50px;
				border-radius: 100%;
				object-fit: cover;
				margin: 0 25px;
			}
		}

		.center {
			padding: 10px;
			background-color: #2cb67d;
			border-radius: 10px;
			margin: 10px 0;

			p {
				font-size: 12px;
			}
		}

		.bottom {
			h5 {
				margin-bottom: 5px;
			}

			h6 {
				color: #94a1b2;
			}
		}
	}
}

@media only screen and (min-width: 500px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}
